<template>
  <c-box
    margin-top="24px"
  >
    <MealScheduleContainer
      :is-edited="isEdited"
      :data="data"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabs"
      :sub-tabs="_subTabs"
    />
  </c-box>
</template>

<script>
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { CBox } from '@chakra-ui/vue'
import MealScheduleContainer from '@/components/meal-plan/meal-plan-schedule/container.vue'
import { mapMutations } from 'vuex'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'
import { reqMenuMealPlan_TriggerForBE_1 } from '@/requests/dietela-api/other'

export default {
  components: {
    CBox,
    MealScheduleContainer,
  },
  mixins: [mixinsTabsMealPlan],
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.init()
        this.getMenuRecommendationWeekDays()
      },
    },
  },
  methods: {
    ...mapMutations({
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
      setMenuRecommendationWeeksDays: 'mealPlan/setMenuRecommendationWeeksDays',
    }),
    async init() {
      // Trigger for BE 1
      await reqMenuMealPlan_TriggerForBE_1(this.$store.getters.axios, {
        clientId: this.$route.params.clientId,
        programId: this.$route.params.programId,
        month: this.$route.params.month,
      })

      const res = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.setMealPlanPreview(res?.data?.data)
      this.data = res?.data?.data
    },
    async getMenuRecommendationWeekDays() {
      const res = await reqNutriMealPlan.getMenuRecommendationWeekDays(this.$store.getters.axios,
        {
          clientId: this.$route.params?.clientId,
          programId: this.$route.params?.programId,
          month: this.$route.params.month,
        },
      )
      this.setMenuRecommendationWeeksDays(res?.data?.data)
    },
  },
}
</script>
